@font-face {
    font-family: 'Agency FB';
    font-style: normal;
    font-weight: normal;
    src: local('Agency FB'), url('../font/AGENCY.woff') format('woff');
}

body {
    background-color: #000;
    margin: 0em
}

 a.lien360:link {
    color: #ddd;
    text-decoration: none
}
a.lien360:visited {
    color: #ddd;
    text-decoration: none
}
a.lien360:hover {
    color: #ff7fe0;
    text-decoration: none
}
a.lien360:active {
    color: #9fffba;
    text-decoration: none
}

a.lienmoodle:link {
  color: #ddd;
  text-decoration: none
}
a.lienmoodle:visited {
  color: #ddd;
  text-decoration: none
}
a.lienmoodle:hover {
  color: #ffbd7f;
  text-decoration: none
}
a.lienmoodle:active {
  color: #9fffba;
  text-decoration: none
}

a.lienvirtuel:link {
  color: #ddd;
  text-decoration: none
}
a.lienvirtuel:visited {
  color: #ddd;
  text-decoration: none
}
a.lienvirtuel:hover {
  color: #7fcaff;
  text-decoration: none
}
a.lienvirtuel:active {
  color: #9fffba;
  text-decoration: none
}

.fluid-1 {
  display: block;
  flex: 1 1 34%;
  min-width: 380px;
}

.fluid-2 {
    display: block;
    flex: 1 1 62%;
    min-width: 380px;
}

@keyframes slide {
    0% {
      left: 0%;
    }
    50% {
      left: 99%;
    }
    100% {
      left: 0%;
    }
  }

  @keyframes colorise {
    0% {background-color:rgb(255, 255, 255);}
    20% {background-color: rgb(255, 182, 182);}
    40% {background-color: rgb(187, 221, 255);}
    60% {background-color: rgb(213, 255, 159);}
    80% {background-color: rgb(207, 167, 255);}
    100% {background-color: rgb(255, 255, 255);}
  }

  .content-slider {
    animation: slide 12s linear infinite
  }

  .content-colorise {
    animation: colorise 30s linear infinite
  }

.spot {
  display: inline-block;
  position: absolute;
  background-color: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
}

  .spot-1 {
  left: 28%; top: 5%;
  transform: scale(1);
  animation: iconpulse 8s infinite;
  animation-delay: 0s
  }

  .spot-2 {
  left: 80%; top: 36%;
  transform: scale(1);
  animation: iconpulse 8s infinite;
  animation-delay: 1s
  }

  .spot-3 {
  left: 60%; top: 20%;
  transform: scale(1);
  animation: iconpulse 8s infinite;
  animation-delay: 2s
  }

  @keyframes iconpulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(200, 200, 256, 1);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(256, 256, 256, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

#aSide {
/*height: 200px;
width: 600px;
position: absolute;
top: calc(50% - 250px);
right: 25%;*/
background-color: transparent
}

#aSide model-viewer {
height: 280px;
width: 600px;
margin: auto;
border: none;
background-color: transparent
}

.contour {
  stroke: transparent;
  stroke-width: 4px;
  stroke-dashoffset: -2000;
  stroke-dasharray: 2000;
  animation-name: contour;
  animation-duration: 6s;
  animation-iteration-count:1;
  }
  
  @keyframes contour {
    0% {
      stroke-dashoffset: -2000;
      stroke: #eee;
    }
    70% {
      stroke-dashoffset:0;  
      stroke: #eee;
    }
    100% {
      stroke-dashoffset:0;
      stroke: transparent;
    }
  }

.backfill {
    fill: transparent;
    animation-name: backfill;
    animation-duration: 6s;
    animation-iteration-count:1;
    animation-fill-mode: forwards;
  }

  @keyframes backfill {
    0% {
      fill: transparent;
    }
    70% {
      fill:#eee;
    }
    100% {
      fill:#eee;
    }
  }
